<template>
    <div class="w-full h-full flex flex-col items-center justify-center p-8">
        <!-- starturl
        <hr>
        <pre>{{message}}</pre> -->
    </div>
</template>

<script>
    export default {
        data(){
            return {
                message: null,
            }
        },
        
        async mounted(){
            if ( localStorage.accessToken && localStorage.portal ) {
                this.message = 'Found accessToken or portal in localStorage';
                const accessToken = localStorage.accessToken;
                const portal = JSON.parse( localStorage.portal );
                const accountId = portal.accountId;
                
                this.$router.push({
                        name: 'portal-home',
                        params: { 
                            accountId: accountId,
                        }
                    }
                );
                
                return;
            }
            
            this.$router.push({name: 'accounts'});
        }
    }
</script>